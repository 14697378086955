import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";

import { SectionHeading } from "shared/Headings";
import { PrimaryButton as PrimaryButtonBase } from "shared/Buttons";
// import { songs } from "data/data";

import { ReactComponent as MusicIcon } from "feather-icons/dist/icons/music.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

import { get } from "utils/DeApi";

import { Link } from "react-router-dom";

const Container = tw.div`relative  `;
const Content = tw.div`max-w-full  mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex px-12 flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex  items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 bg-teal-500 hover:bg-teal-400 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track {
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`h-full flex! lg:w-96! lg:h-99 flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none`;
const CardImage = styled.div((props) => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`,
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const ViewLink = tw.span`text-xs underline`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-auto sm:text-lg hover:bg-teal-400 rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6 bg-teal-500`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);

  const [songsData, setSongsData] = useState();
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const slidesLength = songsData?.length < 3 ? 1 : 3;

  const sliderSettings = {
    arrows: false,
    slidesToShow: slidesLength,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchSongs = () => {
      setError(null);
      setIsLoading(true);

      const contentPromise = get(`contents`);

      contentPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);

          setSongsData(response.data);
        })

        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(contentPromise);
    };
    fetchSongs();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading>Newly Added </Heading>
          <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}>
              <ChevronLeftIcon />
            </PrevButton>
            <NextButton onClick={sliderRef?.slickNext}>
              <ChevronRightIcon />
            </NextButton>
          </Controls>
        </HeadingWithControl>
        <CardSlider ref={setSliderRef} {...sliderSettings}>
          {songsData?.map((song, index) => (
            <Card key={index}>
              <CardImage imageSrc={song.contentThumbnail} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{song.title}</Title>
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <MusicIcon />
                    </IconContainer>
                    <Text>{song.categories[0].categoryName}</Text>
                  </IconWithText>
                </SecondaryInfoContainer>
                <Description>
                  {song?.description?.length > 10
                    ? song.description.substring(0, 100) + "..."
                    : song.description || "N/A"}
                  <Link to={`/song/${song.contentId}`}>
                    <ViewLink>View Full</ViewLink>
                  </Link>
                </Description>
              </TextInfo>
              <Link to={`/song/${song.contentId}`}>
                <PrimaryButton>Lyrics</PrimaryButton>
              </Link>
            </Card>
          ))}
        </CardSlider>
      </Content>
    </Container>
  );
};
