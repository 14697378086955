import React, { useState } from "react";
import tw from "twin.macro";
import { ReactComponent as SearchIcon } from "feather-icons/dist/icons/search.svg";
export const SearchBarComp = tw.div`relative xs:mb-0 lg:mr-6`;
export const SearchIconStyle = tw.span`lg:absolute inset-y-0 right-0 pr-6`;
export const ResponsiveSearchIconStyle = tw.span `absolute inset-y-0 right-0 pr-8  flex items-center xs:right-0 xs:pr-4`;

export const Input = tw.input`py-2 px-4 border border-gray-500 rounded-md mr-2 focus:outline-none focus:ring focus:border-blue-300`;
const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    onSearch(newSearchTerm);
  };

  return (
    <div>
      <SearchBarComp>
        <Input
          type="text"
          placeholder="Search Songs..."
          value={searchTerm}
          onChange={handleSearch}
        />
        <ResponsiveSearchIconStyle>
          <SearchIcon />
        </ResponsiveSearchIconStyle>
      </SearchBarComp>
    </div>
  );
};

export default SearchBar;
