import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const LoaderContainer = styled.div`
  ${tw`flex items-center my-40 justify-center`}
  svg {
    ${tw`animate-spin h-5 w-5 mr-3 bg-secondary-500 `}
  }
`;

const Loader = () => {
  return (
    <LoaderContainer>
      <svg viewBox="0 0 24 24"></svg>
    </LoaderContainer>
  );
};

export default Loader;
