import Footer from "components/App/Footer/Footer";
import Header from "components/App/Header/Header";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import SongList from "./ListView/SongList";

import { ContentHeight } from "shared/Layouts";

const ListSongs = () => {
  return (
    <AnimationRevealPage disabled>
      <Header title="Home" />
      <ContentHeight>
        <SongList />
      </ContentHeight>
      <Footer />
    </AnimationRevealPage>
  );
};

export default ListSongs;
