import Header from "components/App/Header/Header";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import React from "react";
import Detail from "./Details/Detail";
import Footer from "components/App/Footer/Footer";

import { ContentHeight } from "shared/Layouts";

const SongDetail = () => {
  return (
    <AnimationRevealPage disabled>
      <Header title="Song Detail" />
      <ContentHeight>
        <Detail />
      </ContentHeight>
      <Footer />
    </AnimationRevealPage>
  );
};

export default SongDetail;
