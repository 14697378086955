import React from "react";
import tw from "twin.macro";
import { Container as ContainerBase } from "shared/Layouts.js";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`;
const Content = tw.div`max-w-screen-xl mx-auto py-10 `;

const Row = tw.div`flex items-center justify-center flex-col px-8`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm  md:text-sm xs:text-xs text-gray-600`;
export default () => {
  const year = new Date().getFullYear();

  return (
    <Container>
      <Content>
        <Row>
          <CopyrightText>
            &copy; Copyright {year}, Desi Folk Songs All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
