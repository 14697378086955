import Header from "components/App/Header/Header";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import React from "react";
import PoetDetail from "./PoetDetail/PoetDetail";
import Footer from "components/App/Footer/Footer";

import { ContentHeight } from "shared/Layouts";

const Poet = () => {
  return (
    <AnimationRevealPage disabled>
      <Header title="Poet Detail" />
      <ContentHeight>
        <PoetDetail />
      </ContentHeight>
      <Footer />
    </AnimationRevealPage>
  );
};

export default Poet;
