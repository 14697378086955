import React, { useEffect, useRef, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { Container, ContentWithVertical } from "shared/Layouts";
import ReactPlayer from "react-player";

import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

import Lyrics from "./Lyrics/Lyrics";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

import { get } from "utils/DeApi";

const Posts = tw.div`mt-6 sm:-mr-8 xs:mb-10 md:mb-20 flex flex-wrap md:h-96`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/6 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row!  lg:mx-6 h-full sm:px-4 xs:px-4`}
      }
      ${Image} {
        ${tw`sm:h-96 sm:w-1/2 lg:w-1/2 xs:w-1/4 xs:h-96 mt-10`}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg `}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
      ${PoetName} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
      ${Player} {
        ${tw`sm:h-96 my-12 sm:min-h-full sm:w-1/2 lg:w-2/4  xs:mb-48`}
      }
    `}
`;
const Post = tw.div` flex flex-col  rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-40 w-full bg-cover bg-center `}
`;
const Info = tw.div`p-8 mt-1 rounded-lg rounded-t-none`;
const Album = tw.div`uppercase text-secondary-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-secondary-500 after:w-8`;
const Released = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`font-black text-xl text-secondary-1000 hover:text-gray-700 transition duration-300 cursor-pointer`;
const Description = tw.div`lg:text-sm! text-xs!`;
const PoetName = tw.div`lg:text-sm! text-xs! capitalize!`;
const PoetLink = tw.span`lg:text-sm! underline text-xs! capitalize! text-secondary-600 hover:text-secondary-700 transition duration-300`;

const Player = tw.div`justify-center flex h-40!`;

const LanguageSelect = tw.div`xs:mb-8 lg:mx-10`;
const Label = tw.label`mb-2 text-sm font-medium `;
const Select = tw.select`md:text-sm text-xs border mt-10 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 mx-2 xs:w-32 md:w-40 p-2  border-gray-600 placeholder-gray-400  focus:ring-blue-500 focus:border-blue-500`;
export default () => {
  const { id } = useParams();

  const [songsDetail, setSongsDetail] = useState();
  const subscribedPromises = useRef([]);

  const [songsData, setSongsData] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [songLanguage, setSongLanguage] = useState("Both");

  const poet = songsData?.find((item) =>
    item.categories.find((x) => x.categoryName === songsDetail?.title)
  );
  const lyricsNotBoth = songsDetail?.metadata.find(
    (lyrics) => lyrics.key === songLanguage
  )?.value;

  const filterMetaData = (tag) => {
    const filterData = songsDetail?.metadata.find(
      (data) => data.key === tag
    )?.value;

    return filterData;
  };
  const fetchSongs = () => {
    setError(null);
    setIsLoading(true);

    const contentPromise = get(`contents`);

    contentPromise.promise
      .then((response) => {
        setIsLoading(false);
        setError(null);

        setSongsData(response.data);
      })

      .catch((error) => {
        if (!error.isCanceled) {
          setError(error);
          setIsLoading(false);
        }
      });
    subscribedPromises.current.push(contentPromise);
  };

  useEffect(() => {
    const fetchSongsDetails = () => {
      setError(null);
      setIsLoading(true);

      const songPromise = get(`contents/${id}`);

      songPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);
          setSongsDetail(response.data);
        })

        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(songPromise);
    };
    fetchSongsDetails();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [id]);

  useEffect(() => {
    fetchSongs();
  }, []);

  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!songsDetail) return <span></span>;

  return (
    <Container>
      <ContentWithVertical>
        {songsDetail && (
          <>
            <Posts>
              <PostContainer featured={true}>
                <Post>
                  {songsDetail.contentSource ? (
                    <Player>
                      <ReactPlayer
                        light={songsDetail.contentThumbnail}
                        controls={true}
                        playing
                        height={380}
                        url={songsDetail?.contentSource}
                      />
                    </Player>
                  ) : (
                    <Image imageSrc={songsDetail?.contentThumbnail} />
                  )}

                  <Info>
                    <Title>{songsDetail.title}</Title>
                    <Released>
                      Released: {filterMetaData("Released") || "N/A"}
                    </Released>
                    <Album>Album: {filterMetaData("Album") || "N/A"}</Album>
                    <Description>
                      Singer Name: {filterMetaData("Singer") || "N/A"}
                    </Description>

                    <PoetName>
                      Poet:{" "}
                      {poet ? (
                        <Link to={`/poet/${poet?.contentId}`}>
                          <PoetLink> {poet?.title.toLowerCase()}</PoetLink>
                        </Link>
                      ) : (
                        "N/A"
                      )}
                    </PoetName>

                    <Description>
                      {songsDetail?.description
                        ?.split("\n")
                        ?.map((data, index) => (
                          <div key={index}>
                            <p
                              style={
                                /\p{Script=Arabic}/u.test(data)
                                  ? {
                                      fontFamily: "UrduFont",
                                      fontSize: "1rem",
                                      marginTop: "1rem",
                                      textAlign: "center",
                                    }
                                  : {
                                      fontFamily: "Inter,system-ui",
                                      fontWeight: "500",
                                    }
                              }
                            >
                              {data || "N/A"}
                            </p>
                            {/* {data === "" && <br />} */}
                          </div>
                        ))}
                    </Description>
                  </Info>
                </Post>
              </PostContainer>
            </Posts>
            <LanguageSelect>
              <Label>Language : </Label>
              <Select
                defaultValue={songLanguage}
                onChange={(e) => setSongLanguage(e.target.value)}
              >
                <option value="Both">All</option>
                <option value="English">English</option>
                <option value="Urdu">Urdu</option>
              </Select>
            </LanguageSelect>

            <Lyrics
              songLanguage={songLanguage}
              lyrics={
                songLanguage !== "Both"
                  ? lyricsNotBoth
                  : songsDetail?.contentBody
              }
            ></Lyrics>
          </>
        )}
      </ContentWithVertical>
    </Container>
  );
};
