import React, { useEffect } from "react";
import GlobalStyles from "styles/GlobalStyles";
import { css } from "styled-components/macro"; //eslint-disable-line
import "./App.css";

import Home from "components/Home/Home";
import SongDetail from "components/SongDetail";
import SongList from "components/SongList";
import About from "components/About/About";
import Poet from "components/Poet";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { SearchProvider } from "components/SongsSearch/SearchContext";

function App() {
  return (
    <div>
      <GlobalStyles />

      <Router>
        <ScrollToTop />
        <SearchProvider>
          <Routes>
            <Route exact path="/" element={<SongList />} />
            <Route path="/about" element={<About />} />
            {/* <Route exact path="/Home" element={<Home />} /> */}
            <Route path="/song/:id" element={<SongDetail />} />
            <Route path="/poet/:id" element={<Poet />} />
          </Routes>
        </SearchProvider>
      </Router>
    </div>
  );
}

/** Scroll To Top on Navigation */
const ScrollToTop = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return null;
};

export default App;
