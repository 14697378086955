import React from "react";

import { ReactComponent as AlertIcon } from "feather-icons/dist/icons/alert-circle.svg";
import styled from "styled-components";

import tw from "twin.macro";

const ErrorContainer = tw.div`mt-5 rounded-md bg-yellow-500 p-4`;
const SubContainer = tw.div`flex truncate`;
const MarginLeft = tw.div`ml-3`;

const StatusHeading = tw.h2`text-sm font-medium text-yellow-800`;
const Message = tw.div`mt-2 text-sm text-yellow-700`;
const StatusType = tw.div`text-sm text-yellow-700`;

const IconContainer = styled.div`
  ${tw`flex-shrink-0`}
  svg {
    ${tw`h-5 w-5 text-yellow-800`}
  }
`;

const ErrorHandler = ({ error, message }) => {
  let status = "";
  let statusText = "Oh snap!";
  let _message = "Something unexpected happened. Please try again.";
  if (error) {
    status = error.status || status;
    statusText = error.statusText || statusText;
    if (error.data && error.data.error) {
      if ("message" in error.data.error) {
        _message = error.data.error.message;
      } else {
        _message =
          error.data.error[Object.keys(error.data.error)[0]] ?? _message;
      }
    }
  }
  return (
    <ErrorContainer>
      <SubContainer>
        <IconContainer>
          <AlertIcon />
        </IconContainer>
        <MarginLeft>
          <StatusHeading>{statusText}</StatusHeading>
          <Message>
            <p>{message || _message}</p>
          </Message>
          <StatusType>{status}</StatusType>
        </MarginLeft>
      </SubContainer>
    </ErrorContainer>
  );
};

export default ErrorHandler;
