const aboutText = `
<p>Desi Folk Songs have a unique ability to transcend geographical boundaries, acting as a cultural bridge that invites individuals into a realm where captivating melodies and profound poetry intertwine, touching the depths of the human soul. By skillfully translating these songs into English, they find a new abode that opens the doors to a broader audience, enabling them to immerse themselves in the profound beauty and unravel the wisdom embedded within.</p>
<br/>
<p>Many notable Urdu poets and writers were born in the Indian Subcontinent before partition, providing a true depiction of Indian culture, the lifestyle of Indian Muslims, the British Raj, and the challenges of that era. However, as Western culture, technology, and modernism gain popularity worldwide, there has been a tendency to leave behind Urdu literature, cultural songs, nazams, and ghazals. To address this, our goal is to provide digital access to our heritage in the form of traditional songs, ghazals, nazams, and masnawees to an interested audience worldwide.</p>
<br/>
<p>On our platform, one can find not only songs in Urdu but also in other regional languages such as Punjabi. These songs range from recent compositions to ones that date back hundreds of years. The platform serves as a unified channel to access the Sufi kalam of Baba Bulleh Shah, the ghazals of Mirza Ghalib, and the works of more recent poets and writers like Faiz Ahmed Faiz. Even for natives of the Subcontinent, interpreting the complete lyrics and meaning of a song, especially if it's not in Urdu or one of the regional languages, can sometimes be challenging. To enhance the listening experience, we provide lyrics, meanings, and context for these songs, making them more enjoyable and accessible to a wider audience. This is particularly important for songs like Sufi tracks by Nusrat Fateh Ali Khan, Sabri Brothers, and Abida Parveen, which are heard all over the globe. Many people worldwide listen to these songs because of the music and singing, just like how people globally listen to Italian opera even when they don't speak the language.</p>
<br/>
<p>By preserving and promoting these cultural treasures, we aim to bridge the gap and foster a deeper appreciation for Desi Folk Songs and their rich heritage, fostering cross-cultural understanding and enjoyment for people around the world.</p>
`;

export default aboutText;
