import React, { useEffect, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Container, ContentWithVertical } from "shared/Layouts";
import ReactPlayer from "react-player";

import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";

import ErrorHandler from "components/ErrorHandler/ErrorHandler";
import Loader from "components/Loader/Loader";

import { get } from "utils/DeApi";
import PoetDescription from "./PoetDescription/PoetDescription";

const Posts = tw.div`mt-6 sm:-mr-8 xs:mb-10 md:mb-20 flex flex-wrap md:h-96`;
const PostContainer = styled.div`
  ${tw`mt-10 w-full sm:w-1/2 lg:w-1/3 sm:pr-8`}
  ${(props) =>
    props.featured &&
    css`
      ${tw`w-full!`}
      ${Post} {
        ${tw`sm:flex-row!  h-full sm:pr-4`}
      }
      ${Image} {
        ${tw`sm:h-96  sm:w-1/2 lg:w-2/4 `}
      }
      ${Info} {
        ${tw`sm:-mr-4 sm:pl-8 sm:flex-1 sm:rounded-none sm:rounded-r-lg `}
      }
      ${Description} {
        ${tw`text-sm mt-3 leading-loose text-gray-600 font-medium`}
      }
      ${Player} {
        ${tw`sm:h-96 sm:min-h-full sm:w-1/2 lg:w-2/4  xs:mb-48`}
      }
    `}
`;
const Post = tw.div` flex flex-col rounded-lg`;
const Image = styled.div`
  ${(props) =>
    css`
      background-image: url("${props.imageSrc}");
    `}
  ${tw`h-40 mt-12 w-full bg-cover bg-center `}
`;
const Info = tw.div`p-8  rounded-lg rounded-t-none`;
const Album = tw.div`uppercase text-teal-500 text-xs font-bold tracking-widest leading-loose after:content after:block after:border-b-2 after:border-teal-500 after:w-8`;
const Released = tw.div`mt-4 uppercase text-gray-600 italic font-semibold text-xs`;
const Title = tw.div`mt-1 font-black text-2xl text-gray-900  capitalize`;
const Description = tw.div`lg:text-sm! text-xs! capitalize!`;

const Player = tw.div`justify-center flex h-40!`;

export default () => {
  const { id } = useParams();

  const [poetDetail, setPoetDetail] = useState();
  const subscribedPromises = useRef([]);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const filterMetaData = (tag) => {
    const filterData = poetDetail?.metadata?.find(
      (data) => data.key === tag
    )?.value;

    return filterData;
  };

  useEffect(() => {
    const fetchPoetDetails = () => {
      setError(null);
      setIsLoading(true);

      const poetPromise = get(`contents/${id}`);

      poetPromise.promise
        .then((response) => {
          setIsLoading(false);
          setError(null);

          setPoetDetail(response.data);
        })

        .catch((error) => {
          if (!error.isCanceled) {
            setError(error);
            setIsLoading(false);
          }
        });
      subscribedPromises.current.push(poetPromise);
    };
    fetchPoetDetails();
    const promises = subscribedPromises.current;
    return () => {
      promises.forEach((promise) => {
        promise.cancel();
      });
    };
  }, [id]);
  if (isLoading) return <Loader />;
  if (error) return <ErrorHandler error={error} />;
  if (!poetDetail) return <span></span>;
  return (
    <Container>
      <ContentWithVertical>
        {poetDetail && (
          <>
            <Posts>
              <PostContainer featured={true}>
                <Post>
                  {poetDetail.contentSource ? (
                    <Player>
                      <ReactPlayer
                        light={poetDetail.contentThumbnail}
                        controls="true"
                        playing
                        url={poetDetail?.contentSource}
                      />
                    </Player>
                  ) : (
                    <Image imageSrc={poetDetail?.contentThumbnail} />
                  )}

                  <Info>
                    <Title>{poetDetail.title.toLowerCase()}</Title>
                    <Released>Born: {filterMetaData("Born") || "N/A"}</Released>
                    <Album>Books: {filterMetaData("Books") || "N/A"}</Album>
                    <Description>
                      Died: {filterMetaData("Died") || "N/A"}
                    </Description>

                    <Description>
                      {poetDetail?.description
                        ?.split("\n")
                        ?.map((data, index) => (
                          <div key={index}>
                            <p>{data || "N/A"}</p>
                            {data === "" && <br />}
                          </div>
                        ))}
                    </Description>
                  </Info>
                </Post>
              </PostContainer>
            </Posts>

            <PoetDescription description={poetDetail?.contentBody} />
          </>
        )}
      </ContentWithVertical>
    </Container>
  );
};
